<template>
	<b-modal id="receive-dispatch" :title="title" size="xl" ok-title="Proceed" ref="modal" @ok="handleOk" @show="onReset"
		:cancel-disabled="disableConfirmButtons" :ok-disabled="disableConfirmButtons" :no-close-on-backdrop="true">
		<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

		<b-row class="mx-4">
			<b-col lg="4" md="4" sm="12" class="my-1">
				<em class="icon-rocket"></em>&nbsp;
				<strong>SOURCE</strong>
				<br />
				&nbsp; &nbsp; &nbsp;
				{{ sourceCompany }}
			</b-col>

			<b-col lg="4" md="4" sm="12" class="my-1">
				<em class="icon-home"></em>&nbsp;
				<strong>DESTINATION</strong>
				<br />
				&nbsp; &nbsp; &nbsp;
				{{ destinationCompany }}
			</b-col>
		</b-row>

		<b-row class="mx-5 mt-4">
			<b-col lg="4" md="4" sm="12" class="section-header">
				<strong>ASSETS</strong>
			</b-col>
		</b-row>

		<div :key="asset.assetTypeId" v-for="(asset, index) in  selDispatch.assets ">

			<!-- Asset Type Name -->
			<b-row class="mx-5 mt-4">
				<b-col sm="4" class="form-field">
					<strong>{{ asset.assetType }}</strong>
				</b-col>
			</b-row>

			<!-- Expected and Actual -->
			<b-row class="mx-5 mt-2">
				<b-col lg="4" md="4" sm="12" class="form-field">
					<b-form-group label="Expected">
						{{ asset.expectedQuantity }}
					</b-form-group>
				</b-col>

				<b-col lg="4" md="4" sm="12" class="form-field">
					<b-form-group label="Actual">
						<b-form-input :name="asset.assetType + ' actual quantity'" type="number" min="0"
							v-model.number="selAssets[asset.assetTypeId].actualQuantity" v-validate="'required'" />
						<span v-show="errors.has(asset.assetType + ' actual quantity')" class="help-block">
							{{ errors.first(asset.assetType + ' actual quantity') }}
						</span>
					</b-form-group>
				</b-col>
			</b-row>

			<!-- Report Damaged -->
			<b-row class="mx-5 mt-2">
				<b-col lg="4" md="4" sm="12" class="form-field">
					<b-form-group label-for="reportDamaged">
						<b-form-checkbox :name="'reportDamaged-' + index" v-model="selAssets[asset.assetTypeId].hasDamaged">
							Report Damaged
						</b-form-checkbox>
					</b-form-group>
				</b-col>
			</b-row>

			<b-card class="mx-5" v-show="selAssets[asset.assetTypeId].hasDamaged">

				<!-- No of Damaged Count -->
				<b-row class="mt-1">
					<b-col lg="4" md="4" sm="12" class="form-field">
						<b-form-group label="Damaged" description="Enter the number of damaged assets here">
							<b-form-input :name="asset.assetType + ' damage quantity'" type="number" min="0"
								v-model.number="selAssets[asset.assetTypeId].damagedQuantity"
								v-validate="{ required: selAssets[asset.assetTypeId].hasDamaged }" />
							<span v-show="errors.has(asset.assetType + ' damage quantity')" class="help-block">
								{{ errors.first(asset.assetType + ' damage quantity') }}
							</span>
						</b-form-group>
					</b-col>
				</b-row>

				<!-- Upload Images -->
				<b-row class="mt-4">
					<b-col sm="4" class="form-field">
						Images
						<div class="images-label">Upload up to 3 images only</div>
					</b-col>
				</b-row>

				<b-row class="mt-2">

					<b-col lg="4" md="4" sm="12" class="form-field">
						<b-form-group :description="imageInstructions">
							<b-form-file :id="asset.assetType + 'damagedImage0'" placeholder="Choose image"
								:ref="asset.assetType + 'damagedImage0fileInput'" accept="image/png"
								@change="onSelectAssetPhoto($event, asset.assetTypeId, 0)">
								<template slot="file-name" slot-scope="{ names }">
									<b-badge variant="dark">{{ names[0] }}</b-badge>
									<b-badge v-if="names.length > 1" variant="dark" class="ml-1">+ {{
										names.length - 1
									}} More files</b-badge>
								</template>
							</b-form-file>
						</b-form-group>

						<!-- Image Preview -->
						<div v-if="hasImage(asset.assetTypeId, 0)">
							<b-card overlay :img-src="selAssetImages[asset.assetTypeId][0].url" img-alt="Card Image"
								text-variant="white" class="mb-0">
								<b-card-text class="text-right">
									<b-button variant="danger" size="sm" @click="onRemoveAssetPhoto(asset, 0)">
										<em class="fa fa-trash"></em>
									</b-button>
								</b-card-text>
							</b-card>
							<b-progress v-if="selAssetImages[asset.assetTypeId][0].status === 'uploading'"
								:value="selAssetImages[asset.assetTypeId][0].percentage"
								:max="selAssetImages[asset.assetTypeId][0].name" variant="success" height="8px" animated />
						</div>
					</b-col>

					<b-col lg="4" md="4" sm="12" class="form-field">
						<b-form-group :description="imageInstructions">
							<b-form-file :id="asset.assetType + 'damagedImage1'" placeholder="Choose image"
								:ref="asset.assetType + 'damagedImage1fileInput'" accept="image/png"
								@change="onSelectAssetPhoto($event, asset.assetTypeId, 1)">
								<template slot="file-name" slot-scope="{ names }">
									<b-badge variant="dark">{{ names[0] }}</b-badge>
									<b-badge v-if="names.length > 1" variant="dark" class="ml-1">+ {{
										names.length - 1
									}} More files</b-badge>
								</template>
							</b-form-file>
						</b-form-group>

						<!-- Image Preview -->
						<div v-if="hasImage(asset.assetTypeId, 1)">
							<b-card overlay :img-src="selAssetImages[asset.assetTypeId][1].url" img-alt="Card Image"
								text-variant="white" class="mb-0">
								<b-card-text class="text-right">
									<b-button variant="danger" size="sm" @click="onRemoveAssetPhoto(asset, 1)">
										<em class="fa fa-trash"></em>
									</b-button>
								</b-card-text>
							</b-card>
							<b-progress v-if="selAssetImages[asset.assetTypeId][1].status === 'uploading'"
								:value="selAssetImages[asset.assetTypeId][1].percentage"
								:max="selAssetImages[asset.assetTypeId][1].name" variant="success" height="8px" animated />
						</div>
					</b-col>

					<b-col lg="4" md="4" sm="12" class="form-field">
						<b-form-group :description="imageInstructions">
							<b-form-file :id="asset.assetType + 'damagedImage2'" placeholder="Choose image"
								:ref="asset.assetType + 'damagedImage2fileInput'" accept="image/png"
								@change="onSelectAssetPhoto($event, asset.assetTypeId, 2)">
								<template slot="file-name" slot-scope="{ names }">
									<b-badge variant="dark">{{ names[0] }}</b-badge>
									<b-badge v-if="names.length > 1" variant="dark" class="ml-1">+ {{
										names.length - 1
									}} More files</b-badge>
								</template>
							</b-form-file>
						</b-form-group>

						<!-- Image Preview -->
						<div v-if="hasImage(asset.assetTypeId, 2)">
							<b-card overlay :img-src="selAssetImages[asset.assetTypeId][2].url" img-alt="Card Image"
								text-variant="white" class="mb-0">
								<b-card-text class="text-right">
									<b-button variant="danger" size="sm" @click="onRemoveAssetPhoto(asset, 2)">
										<em class="fa fa-trash"></em>
									</b-button>
								</b-card-text>
							</b-card>
							<b-progress v-if="selAssetImages[asset.assetTypeId][2].status === 'uploading'"
								:value="selAssetImages[asset.assetTypeId][2].percentage"
								:max="selAssetImages[asset.assetTypeId][2].name" variant="success" height="8px" animated />
						</div>
					</b-col>
				</b-row>

				<!-- Remarks -->
				<b-row class="mt-4">
					<b-col lg="6" md="6" sm="12" class="form-field">
						<b-form-group label="Remarks" label-for="remarks" description>
							<b-form-textarea :name="asset.assetType + ' remarks'" type="text"
								v-model="selAssets[asset.assetTypeId].damagedRemarks" maxlength="200" v-validate="{
									required: selAssets[asset.assetTypeId].hasDamaged,
									regex: /^([A-Za-z0-9\s:;,.!'\/()-_]{1,200})$/,
								}" :rows="3" placeholder="Remarks">
							</b-form-textarea>
							<span v-show="errors.has(asset.assetType + ' remarks')" class="help-block">{{
								errors.first(asset.assetType + ' remarks')
							}}</span>
						</b-form-group>
					</b-col>
				</b-row>
			</b-card>

			<b-row class="mx-3 mt-4">
				<b-col sm="12">
					<hr />
				</b-col>
			</b-row>

		</div>
	</b-modal>
</template>

<script>
// Util
import { DateUtil } from '@/utils/dateutil';
import { DispatchUtil } from '@/utils/dispatchUtil';
import { FileUtil } from '@/utils/fileUtil';

// API
import dispatchApi from '@/api/dispatchApi';

// Others
import EventBus from '@/shared/event-bus';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import _ from 'lodash';
import { storage } from '@/config/firebase';

export default {
	name: 'receive-dispatch',
	props: {
		loggedUser: {
			type: Object,
			required: true,
		},
	},
	components: {
		Loading,
	},
	data() {
		return {
			selAssets: {},
			selAssetImages: {},
			selDispatch: {},
			isLoading: false,
			currLocation: null,
		};
	},
	computed: {
		title() {
			return 'Receive Dispatch ' + this.selDispatch.dispatchId;
		},
		disableConfirmButtons() {
			return this.isLoading;
		},
		sourceCompany() {
			if (!_.isEmpty(this.selDispatch)) {
				return DispatchUtil.getCompanyLocationDisplay(this.selDispatch.source);
			}
			return '';
		},
		destinationCompany() {
			if (!_.isEmpty(this.selDispatch)) {
				return DispatchUtil.getCompanyLocationDisplay(
					this.selDispatch.destination
				);
			}
			return '';
		},
		imageInstructions() {
			return "*32-bit PNG, 1000px by 1000px, up to 1 MB";
		}
	},
	mounted() {
		EventBus.$on('onUpdateSelDispatch', (selDispatch) => {
			this.getCurrentLocation();

			if (!_.isEmpty(selDispatch)) {
				this.selDispatch = DispatchUtil.cleanupFields(selDispatch);
				for (const asset of selDispatch.assets) {
					this.$set(this.selAssets, asset.assetTypeId, asset);

					if (asset.images && !_.isEmpty(asset.images)) {
						this.$set(this.selAssetImages, asset.assetTypeId, asset.images);
					} else {
						this.$set(this.selAssetImages, asset.assetTypeId, []);
					}
				}
			}

		});
	},
	methods: {
		async getCurrentLocation() {
			try {
				this.currLocation = await this.$getCurrentLocation();
			} catch (error) {
				this.$toaster.error(
					'Error loading data. Please reload the page again.'
				);
			}
		},

		hasImage(assetTypeId, index) {
			return this.selAssetImages
				&& this.selAssetImages[assetTypeId] && !_.isEmpty(this.selAssetImages[assetTypeId])
				&& this.selAssetImages[assetTypeId][index] && !_.isEmpty(this.selAssetImages[assetTypeId][index])
				&& this.selAssetImages[assetTypeId][index].url
		},

		async handleOk(evt) {
			// Prevent modal from closing
			evt.preventDefault();

			// show loading indicator
			this.isLoading = true;

			let isValid = await this.$validator.validateAll();
			if (!isValid) {
				this.$toaster.warning('Please address the field/s with invalid input');
				this.isLoading = false;
				return;
			}
			this.processForm();

			if (!this.validateActualQuantity()) {
				this.$toaster.warning(
					'Actual assets must be greater than 0 but less than the Expected assets'
				);

				// hide loading indicator
				this.isLoading = false;
				return;
			}
			if (!this.validateDamagedQuantity()) {
				this.$toaster.warning(
					'Damaged assets must not exceed the Actual assets'
				);

				// hide loading indicator
				this.isLoading = false;
				return;
			}

			await this.handleSubmit();
		},

		processForm() {
			const dispatchId = this.selDispatch.dispatchId;
			const assets = this.selDispatch.assets;

			for (const asset of assets) {
				const selAsset = this.selAssets[asset.assetTypeId];
				asset.actualQuantity = selAsset.actualQuantity;
				asset.hasDamaged = selAsset.hasDamaged;

				if (asset.hasDamaged) {
					asset.damagedQuantity = selAsset.damagedQuantity;
					asset.remarks = selAsset.remarks;
					asset.images = this.getAssetMedia(dispatchId, asset.assetTypeId);
				} else {
					asset.damagedQuantity = 0;
					asset.remarks = "";
					asset.images = [];
				}
			}
		},

		getAssetMedia(dispatchId, assetTypeId) {
			const assetImages = this.selAssetImages[assetTypeId];

			const images = assetImages
				.filter((image, i) => this.hasImage(assetTypeId, i))
				.map((image, i) => ({
					name: `DA_${dispatchId}_${assetTypeId}_${i}.jpg`,
					url: image.url,
					longitude: this.currLocation ? this.currLocation.lng : 0,
					latitude: this.currLocation ? this.currLocation.lat : 0,
				}));

			return images;
		},

		validateActualQuantity() {
			let assets = this.selDispatch.assets;
			for (const asset of assets) {
				if (asset.actualQuantity === 0) {
					return false;
				}
				if (asset.actualQuantity > asset.expectedQuantity || asset.actualQuantity < 0) {
					return false;
				}
			}
			return true;
		},
		validateDamagedQuantity() {
			let assets = this.selDispatch.assets;
			for (const asset of assets) {
				if (asset.hasDamaged && (asset.damagedQuantity > asset.actualQuantity || asset.damagedQuantity < 0)) {
					return false;
				}
			}
			return true;
		},

		async handleSubmit() {
			let dispatchId = this.selDispatch.dispatchId;

			try {
				let { data } = await dispatchApi.receiveDispatch(
					this.selDispatch,
					this.loggedUser.id,
					DateUtil.getCurrentTimestamp()
				);

				if (data.isSuccess) {

					// upload asset photos
					await this.uploadAssetPhotos(data.dispatch);

					this.$toaster.success(
						`Dispatch "${dispatchId}" was successfully received.`
					);
					EventBus.$emit('onCloseReceiveDispatch', data.dispatch);
					this.$refs.modal.hide();
				} else {
					// hide loading indicator
					this.isLoading = false;
					this.$toaster.warning(data.message);
				}
			} catch (error) {
				// hide loading indicator
				this.isLoading = false;
				this.$toaster.error(
					`Error receiving dispatch ${dispatchId}. Please try again.`
				);
			}
		},

		// Image selection and upload
		onRemoveAssetPhoto(asset, index) {
			this.$set(this.selAssetImages[asset.assetTypeId], index, {});

			let assetType = asset.assetType;
			console.log("ref: " + assetType + 'damagedImage' + index + 'fileInput');
			console.log(this.$refs[assetType + 'damagedImage' + index + 'fileInput']);
			this.$refs[assetType + 'damagedImage' + index + 'fileInput'][0].reset();
		},
		onSelectAssetPhoto(evt, assetTypeId, index) {
			const vm = this;
			const file = evt.target.files[0];

			if (!FileUtil.isValidImgFileType(file)) {
				this.$toaster.error(
					'Invalid File Type: Please import a valid company logo in PNG or JPEG format.'
				);
				vm.$set(vm.selAssetImages[assetTypeId], index, {});
				return;
			}

			const url = URL.createObjectURL(file);
			let dimensions = { w: 0, h: 0 };

			const image = new Image();
			image.onload = function () {
				dimensions.w = image.width;
				dimensions.h = image.height;

				if (dimensions.w > 1000 || dimensions.h > 1000) {
					vm.$toaster.warning(
						"Damage asset(s) photo's width and height shouldn't be greater than 1000 pixels"
					);
				} else {
					vm.$set(vm.selAssetImages[assetTypeId], index, {
						url: url,
						file: file,
						fbStoragePath: 'images/disposals',
						isNew: true,
					});
				}
			};
			image.src = url;
		},
		async uploadAssetPhotos(dispatch) {
			const assets = dispatch.assets;
			for (let x = 0; x < assets.length; x++) {
				const asset = assets[x];

				let medias = this.selAssetImages[asset.assetTypeId];
				for (let i = 0; i < medias.length; i++) {
					let media = medias[i];
					if (media && media.isNew) {
						media['index'] = i;
						media['name'] = asset.images[i].name;
						media['assetTypeId'] = asset.assetTypeId;
						const result = await this.firebaseUploadImage(media);

						if (result.name && result.url) {
							dispatch.assets[x].images[i].url = result.url;

							let { data } = await dispatchApi.saveDispatch(
								dispatch,
								this.loggedUser.id,
								DateUtil.getCurrentTimestamp()
							);

							if (data.isSuccess) {
								this.onUploadingInProgress(media, 'success');
							}
						}
					}
				}
			}
		},
		async firebaseUploadImage(media) {
			this.onUploadingInProgress(media, 'uploading', 0);

			return new Promise((resolve, reject) => {
				let storageRef = storage.ref(`${media.fbStoragePath}/${media.name}`); // Use media.name instead of image.name
				let task = storageRef.put(media.file); // Use media.file instead of image.file

				task.on(
					'state_changed',
					(snapshot) => {
						let percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
						this.onUploadingInProgress(media, 'uploading', percentage); // Pass media instead of i
					},
					(error) => {
						reject(error);
						this.onResetUploadingState(media); // Pass media instead of i
					},
					() => {
						task.snapshot.ref.getDownloadURL().then((url) => {
							resolve({ name: media.name, url: url });
						});
					}
				);
			});
		},
		onUploadingInProgress(media, status, percentage = 0) {
			this.selAssetImages[media.assetTypeId][media.index].name = media.name;
			this.selAssetImages[media.assetTypeId][media.index].percentage = percentage;
			this.selAssetImages[media.assetTypeId][media.index].status = status;
		},
		onResetUploadingState(media) {
			this.selAssetImages[media.assetTypeId][media.index].name = '';
			this.selAssetImages[media.assetTypeId][media.index].percentage = 0;
			this.selAssetImages[media.assetTypeId][media.index].status = '';
		},
		onReset() {
			// hide loading indicator
			this.isLoading = false;

			// reset validation
			this.$validator.reset();
			this.errors.clear();
		},
	},

	beforeDestroy() {
		EventBus.$off('onUpdateSelDispatch');
	},
};
</script>

<style scoped>
.scrollable-container {
	max-height: 400px;
	/* Set the desired maximum height */
	overflow-y: auto;
	/* Enable vertical scrolling */
}

.images-label {
	color: #F18F01 !important;
	font-size: 12px;
	font-style: italic;
}
</style>
